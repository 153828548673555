<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="년도"
            :range="true"
            type="year"
            default="today"
            name="years"
            v-model="searchParam.years">
          </c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="환경부 등록자료 이력"
      tableId="grid"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'env-gov-data-mst',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'plantName' },
          { index: 1, colName: 'envGovDataTypeName' },
        ],
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
          {
            fix: true,
            name: 'envGovDataTypeName',
            field: 'envGovDataTypeName',
            label: '환경부 등록자료 구분명',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            fix: true,
            name: 'year',
            field: 'year',
            label: '년도',
            style: 'width:60px',
            align: 'center',
            sortable: false,
          },
          {
            fix: true,
            name: 'envGovDataUnitName',
            field: 'envGovDataUnitName',
            label: '단위',
            style: 'width:60px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'month01',
            field: 'month01',
            label: '1월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month02',
            field: 'month02',
            label: '2월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month03',
            field: 'month03',
            label: '3월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month04',
            field: 'month04',
            label: '4월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month05',
            field: 'month05',
            label: '5월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month06',
            field: 'month06',
            label: '6월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month07',
            field: 'month07',
            label: '7월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month08',
            field: 'month08',
            label: '8월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month09',
            field: 'month09',
            label: '9월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month10',
            field: 'month10',
            label: '10월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month11',
            field: 'month11',
            label: '11월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'month12',
            field: 'month12',
            label: '12월',
            style: 'width:100px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'monthTotal',
            field: 'monthTotal',
            label: '누계',
            style: 'width:120px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        years: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.air.govdata.history.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },  
  }
};
</script>
